import { Link, useActionData } from '@remix-run/react';

import { Button, Input, Heading, Text } from 'aslan';
import { useT } from 'i18n';

import { fieldErrorMessage } from '~/helpers/errors';
import { loginPath, registerPath } from '~/helpers/routes';

import type { ErrorType } from 'utils';

interface ActionDataType {
  recoveryInstructions?: string;
  errors?: ErrorType[];
}

export default function Verify({
  onLinkClick,
  formId,
}: {
  onLinkClick: () => void;
  formId: string;
}) {
  const data = useActionData<ActionDataType>();
  const t = useT('translation', 'verify');

  return (
    <>
      <Heading as="h1" size="3xl" className="mb-5 font-serif">
        {t('title')}
      </Heading>
      <Text size="lg" className="mb-6 font-sans">
        {t('subtitle')}
      </Text>
      <fieldset className="my-6 flex flex-col items-center">
        <Input
          label={t('code')}
          inputClassName="remove-arrow"
          placeholder="******"
          name="code"
          type="number"
          inputMode="numeric"
          pattern="\d*"
          error={fieldErrorMessage(data?.errors ?? [], 'code')?.message}
        />
        <Button
          className="mt-6"
          color="yellow"
          id={`${formId}Button`}
          isFullWidth
          isRounded
          name="action"
          value="signup"
        >
          {t('verifyButton')}
        </Button>
        <Text
          as="span"
          size="sm"
          className="w-full flex justify-center items-center mt-6"
        >
          {t('resendMessage')}
        </Text>
        <Button className="mt-1" color="brown" name="action" value="sendOTP">
          {t('resendVerificationButton')}
        </Button>
      </fieldset>
      <Text
        as="span"
        size="sm"
        className="w-full flex justify-center items-center"
      >
        {t('goBackTo')}{' '}
        <Link
          to={loginPath()}
          className="underline hover:no-underline mx-2"
          onClick={onLinkClick}
        >
          {`${t('signIn')}.`}
        </Link>{' '}
        {t('needNewAccount')}{' '}
        <Link
          to={registerPath()}
          className="underline hover:no-underline ml-2"
          onClick={onLinkClick}
        >
          {t('signUp')}
        </Link>
      </Text>
    </>
  );
}
